<template>
  <div v-loading="isLoading" class="home">
    <div
      :class="['container container--full-screen', { mobileScreen: isMobile }]"
    >
      <div class="header">
        <iq-title class-name="mobileHeader">Квоты:</iq-title>
        <div class="header--info">
          <div class="header--info-limits">
            <div class="limits-text">{{ manualsInfoText.text }}</div>
            <div
              v-if="manualsInfoText.quantity"
              :class="['limits-value', manualsInfoText.class]"
            >
              {{ manualsInfoText.quantity }}
            </div>
          </div>
          <iq-button
            color="main-dark"
            :size="LimitsButtonSize"
            @onClick="openParkingModal"
          >
            <i class="el-icon-bell header--icon" />
            {{ totalParkedText }}
          </iq-button>
        </div>
      </div>

      <template v-if="isShowQuotas">
        <transition name="el-fade-in-linear">
          <div>
            <QuotaDay
              v-for="item in getKeysQuotaSortable"
              :key="item"
              :day-data="getQuota(item)"
            />
          </div>
        </transition>
      </template>

      <template v-if="!isShowQuotas && !isLoading">
        <transition name="el-fade-in-linear">
          <QuotaEmpty />
        </transition>
      </template>

      <dialog-add-quota />
      <dialog-parking-info :list="totalParkedCultures" />
    </div>
  </div>
</template>

<script>
import { DIALOG_PARKING_INFO } from '@/constants/dialogs'
import {
  FETCH_LIMIT_QUOTAS,
  GET_IS_LOADING_QUOTAS,
  GET_MANUALS_INFO,
  GET_QUOTAS,
  GET_TOTAL_PARKED,
} from '@/views/home/store/actions'
import { GET_DAY } from '@/constants/date'
import {
  GET_IS_ALLOWED_MANUAL_TS,
  GET_IS_MOBILE,
  GET_TERMINAL_CURRENT_ID,
} from '@/store/actions'
import { getTzTime, nowTz } from '@/core'
import { mapActions, mapGetters } from 'vuex'
import API from '@/api/index'
import DialogAddQuota from '@/views/home/components/dialogs/dialogAddQuota/DialogAddQuota'
import DialogParkingInfo from '@/views/home/components/dialogs/dialogParkingInfo/DialogParkingInfo'
import IqButton from '@/views/ui/components/buttons/IqButton'
import IqTitle from '@/views/ui/components/typography/IqTitle'
import QuotaDay from '@/views/home/components/quota-day/QuotaDay'
import QuotaEmpty from '@/views/home/components/quota-empty/QuotaEmpty'

export default {
  name: 'Home',
  components: {
    QuotaEmpty,
    IqButton,
    IqTitle,
    DialogAddQuota,
    QuotaDay,
    DialogParkingInfo,
  },
  data() {
    return {
      totalParkedCultures: [],
    }
  },
  computed: {
    ...mapGetters({
      getTerminalCurrentId: GET_TERMINAL_CURRENT_ID,
      quotasList: GET_QUOTAS,
      totalParked: GET_TOTAL_PARKED,
      manualsInfo: GET_MANUALS_INFO,
      canCreateManualsTs: GET_IS_ALLOWED_MANUAL_TS,
      isLoading: GET_IS_LOADING_QUOTAS,
      isMobile: GET_IS_MOBILE,
    }),
    isShowQuotas() {
      return Object.keys(this.quotasList).length > 0
    },
    getKeysQuotaSortable() {
      return Object.keys(this.quotasList).sort()
    },
    totalParkedText() {
      return Number(this.totalParked) > 0
        ? `На парковке ${this.totalParked} авто`
        : 'На парковке пусто'
    },
    getDate() {
      return {
        day: getTzTime(nowTz(), GET_DAY),
      }
    },
    manualsInfoText() {
      if (!this.canCreateManualsTs) {
        return {
          text: 'Лимит ручных таймслотов: отключен',
          quantity: null,
        }
      }
      if (!this.manualsInfo.manuals_enabled) {
        return {
          text: 'Нет лимита на ручные ТС',
          quantity: null,
          class: '',
        }
      }
      if (this.manualsInfo.manuals_used >= this.manualsInfo.manuals_limit) {
        return {
          text: 'Лимит ручных ТС исчерпан:',
          quantity: '0 / ' + this.manualsInfo.manuals_limit,
          class: 'warning,',
        }
      }

      return {
        text: `Остаток ручных ТС на ${this.getDate.day}:`,
        quantity: `${
          this.manualsInfo.manuals_limit - this.manualsInfo.manuals_used
        } / ${this.manualsInfo.manuals_limit}`,
        class: 'normal',
      }
    },
    LimitsButtonSize() {
      return this.isMobile ? 'extraMini' : 'small'
    },
  },
  watch: {
    getTerminalCurrentId() {
      this.getQuotas()
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getQuotas()
    })
  },
  methods: {
    ...mapActions({
      getQuotas: FETCH_LIMIT_QUOTAS,
    }),
    getQuota(key) {
      return this.quotasList[key]
    },
    openParkingModal() {
      if (this.totalParked) {
        API.quota
          .parkingStats(this.getTerminalCurrentId)
          .then(data => (this.totalParkedCultures = data.data))
          .catch(error => console.log(error))
        this.setDialog({ name: DIALOG_PARKING_INFO, visible: true })
      }
    },
  },
}
</script>

<style lang="sass">
.home
  align-self: stretch
  flex: 1 0 100%
  .container--full-screen
    margin-bottom: 50px
  .mobileScreen
    padding: 0 16px 40px
.header
  display: flex
  justify-content: space-between
  align-items: center
  flex-wrap: wrap
  &--icon
    margin-right: 5px
  &--info
    display: flex
    align-items: center
    flex-wrap: wrap
    &-limits
      display: flex
      margin-right: 20px
      color: $main-font
      .limits-value
        margin-left: 6px
        &.warning
          color: $color-orange-primary
          font-weight: bold
        &.normal
          font-weight: bold

@media (max-width: 1199px)
  .home .header .mobileHeader
    font-size: 22px
    font-weight: 500

@media (max-width: 610px)
  .header
    &--info
      flex-wrap: nowrap
      justify-content: space-between
      width: 100%
      margin-bottom: 30px

@media (max-width: 550px)
  .header
    &--info
      column-gap: 10px
      &-limits
        max-width: 145px
        margin: 0
        .limits-text
          display: contents
        .limits-value
          display: contents
</style>
