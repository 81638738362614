var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "home",
    },
    [
      _c(
        "div",
        {
          class: [
            "container container--full-screen",
            { mobileScreen: _vm.isMobile },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("iq-title", { attrs: { "class-name": "mobileHeader" } }, [
                _vm._v("Квоты:"),
              ]),
              _c(
                "div",
                { staticClass: "header--info" },
                [
                  _c("div", { staticClass: "header--info-limits" }, [
                    _c("div", { staticClass: "limits-text" }, [
                      _vm._v(_vm._s(_vm.manualsInfoText.text)),
                    ]),
                    _vm.manualsInfoText.quantity
                      ? _c(
                          "div",
                          {
                            class: ["limits-value", _vm.manualsInfoText.class],
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.manualsInfoText.quantity) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "iq-button",
                    {
                      attrs: { color: "main-dark", size: _vm.LimitsButtonSize },
                      on: { onClick: _vm.openParkingModal },
                    },
                    [
                      _c("i", { staticClass: "el-icon-bell header--icon" }),
                      _vm._v(" " + _vm._s(_vm.totalParkedText) + " "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isShowQuotas
            ? [
                _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
                  _c(
                    "div",
                    _vm._l(_vm.getKeysQuotaSortable, function (item) {
                      return _c("QuotaDay", {
                        key: item,
                        attrs: { "day-data": _vm.getQuota(item) },
                      })
                    }),
                    1
                  ),
                ]),
              ]
            : _vm._e(),
          !_vm.isShowQuotas && !_vm.isLoading
            ? [
                _c(
                  "transition",
                  { attrs: { name: "el-fade-in-linear" } },
                  [_c("QuotaEmpty")],
                  1
                ),
              ]
            : _vm._e(),
          _c("dialog-add-quota"),
          _c("dialog-parking-info", {
            attrs: { list: _vm.totalParkedCultures },
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }